/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import {
  GrpcMessage,
  RecursivePartial,
  ToProtobufJSONOptions
} from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as googleProtobuf000 from '@ngx-grpc/well-known-types';
import * as googleProtobuf001 from '@ngx-grpc/well-known-types';
import * as goal_transaction_enums002 from './goal-transaction-enums.pb';
import * as goal_transaction_type_enums003 from './goal-transaction-type-enums.pb';
import * as goal_transaction_payment_enum004 from './goal-transaction-payment-enum.pb';
import * as goal_transaction_origin_type_enum005 from './goal-transaction-origin-type-enum.pb';
import * as common_message006 from './common-message.pb';
import * as googleProtobuf007 from '@ngx-grpc/well-known-types';
/**
 * Message implementation for currency_indicator.AddCurrencyIndicatorRequest
 */
export class AddCurrencyIndicatorRequest implements GrpcMessage {
  static id = 'currency_indicator.AddCurrencyIndicatorRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new AddCurrencyIndicatorRequest();
    AddCurrencyIndicatorRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: AddCurrencyIndicatorRequest) {
    _instance.value = _instance.value || 0;
    _instance.date = _instance.date || undefined;
    _instance.sourceCurrencyId = _instance.sourceCurrencyId || 0;
    _instance.destinationCurrencyId = _instance.destinationCurrencyId || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: AddCurrencyIndicatorRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 4:
          _instance.value = _reader.readDouble();
          break;
        case 5:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 6:
          _instance.sourceCurrencyId = _reader.readInt32();
          break;
        case 7:
          _instance.destinationCurrencyId = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    AddCurrencyIndicatorRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: AddCurrencyIndicatorRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.value) {
      _writer.writeDouble(4, _instance.value);
    }
    if (_instance.date) {
      _writer.writeMessage(
        5,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.sourceCurrencyId) {
      _writer.writeInt32(6, _instance.sourceCurrencyId);
    }
    if (_instance.destinationCurrencyId) {
      _writer.writeInt32(7, _instance.destinationCurrencyId);
    }
  }

  private _value: number;
  private _date?: googleProtobuf001.Timestamp;
  private _sourceCurrencyId: number;
  private _destinationCurrencyId: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of AddCurrencyIndicatorRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<AddCurrencyIndicatorRequest.AsObject>) {
    _value = _value || {};
    this.value = _value.value;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.sourceCurrencyId = _value.sourceCurrencyId;
    this.destinationCurrencyId = _value.destinationCurrencyId;
    AddCurrencyIndicatorRequest.refineValues(this);
  }
  get value(): number {
    return this._value;
  }
  set value(value: number) {
    this._value = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get sourceCurrencyId(): number {
    return this._sourceCurrencyId;
  }
  set sourceCurrencyId(value: number) {
    this._sourceCurrencyId = value;
  }
  get destinationCurrencyId(): number {
    return this._destinationCurrencyId;
  }
  set destinationCurrencyId(value: number) {
    this._destinationCurrencyId = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    AddCurrencyIndicatorRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): AddCurrencyIndicatorRequest.AsObject {
    return {
      value: this.value,
      date: this.date ? this.date.toObject() : undefined,
      sourceCurrencyId: this.sourceCurrencyId,
      destinationCurrencyId: this.destinationCurrencyId
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): AddCurrencyIndicatorRequest.AsProtobufJSON {
    return {
      value: this.value,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      sourceCurrencyId: this.sourceCurrencyId,
      destinationCurrencyId: this.destinationCurrencyId
    };
  }
}
export module AddCurrencyIndicatorRequest {
  /**
   * Standard JavaScript object representation for AddCurrencyIndicatorRequest
   */
  export interface AsObject {
    value: number;
    date?: googleProtobuf001.Timestamp.AsObject;
    sourceCurrencyId: number;
    destinationCurrencyId: number;
  }

  /**
   * Protobuf JSON representation for AddCurrencyIndicatorRequest
   */
  export interface AsProtobufJSON {
    value: number;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    sourceCurrencyId: number;
    destinationCurrencyId: number;
  }
}

/**
 * Message implementation for currency_indicator.ListCurrencyIndicatorResponse
 */
export class ListCurrencyIndicatorResponse implements GrpcMessage {
  static id = 'currency_indicator.ListCurrencyIndicatorResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ListCurrencyIndicatorResponse();
    ListCurrencyIndicatorResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ListCurrencyIndicatorResponse) {
    _instance.values = _instance.values || [];
    _instance.currentPage = _instance.currentPage || 0;
    _instance.pageCount = _instance.pageCount || 0;
    _instance.pageSize = _instance.pageSize || 0;
    _instance.rowCount = _instance.rowCount || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: ListCurrencyIndicatorResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message006.CurrencyIndicatorModel();
          _reader.readMessage(
            messageInitializer1,
            common_message006.CurrencyIndicatorModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        case 2:
          _instance.currentPage = _reader.readInt32();
          break;
        case 3:
          _instance.pageCount = _reader.readInt32();
          break;
        case 4:
          _instance.pageSize = _reader.readInt32();
          break;
        case 5:
          _instance.rowCount = _reader.readInt32();
          break;
        default:
          _reader.skipField();
      }
    }

    ListCurrencyIndicatorResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: ListCurrencyIndicatorResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message006.CurrencyIndicatorModel.serializeBinaryToWriter
      );
    }
    if (_instance.currentPage) {
      _writer.writeInt32(2, _instance.currentPage);
    }
    if (_instance.pageCount) {
      _writer.writeInt32(3, _instance.pageCount);
    }
    if (_instance.pageSize) {
      _writer.writeInt32(4, _instance.pageSize);
    }
    if (_instance.rowCount) {
      _writer.writeInt32(5, _instance.rowCount);
    }
  }

  private _values?: common_message006.CurrencyIndicatorModel[];
  private _currentPage: number;
  private _pageCount: number;
  private _pageSize: number;
  private _rowCount: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ListCurrencyIndicatorResponse to deeply clone from
   */
  constructor(
    _value?: RecursivePartial<ListCurrencyIndicatorResponse.AsObject>
  ) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message006.CurrencyIndicatorModel(m)
    );
    this.currentPage = _value.currentPage;
    this.pageCount = _value.pageCount;
    this.pageSize = _value.pageSize;
    this.rowCount = _value.rowCount;
    ListCurrencyIndicatorResponse.refineValues(this);
  }
  get values(): common_message006.CurrencyIndicatorModel[] | undefined {
    return this._values;
  }
  set values(value: common_message006.CurrencyIndicatorModel[] | undefined) {
    this._values = value;
  }
  get currentPage(): number {
    return this._currentPage;
  }
  set currentPage(value: number) {
    this._currentPage = value;
  }
  get pageCount(): number {
    return this._pageCount;
  }
  set pageCount(value: number) {
    this._pageCount = value;
  }
  get pageSize(): number {
    return this._pageSize;
  }
  set pageSize(value: number) {
    this._pageSize = value;
  }
  get rowCount(): number {
    return this._rowCount;
  }
  set rowCount(value: number) {
    this._rowCount = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ListCurrencyIndicatorResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ListCurrencyIndicatorResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject()),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): ListCurrencyIndicatorResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options)),
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      pageSize: this.pageSize,
      rowCount: this.rowCount
    };
  }
}
export module ListCurrencyIndicatorResponse {
  /**
   * Standard JavaScript object representation for ListCurrencyIndicatorResponse
   */
  export interface AsObject {
    values?: common_message006.CurrencyIndicatorModel.AsObject[];
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }

  /**
   * Protobuf JSON representation for ListCurrencyIndicatorResponse
   */
  export interface AsProtobufJSON {
    values: common_message006.CurrencyIndicatorModel.AsProtobufJSON[] | null;
    currentPage: number;
    pageCount: number;
    pageSize: number;
    rowCount: number;
  }
}

/**
 * Message implementation for currency_indicator.GetLastValuesResponse
 */
export class GetLastValuesResponse implements GrpcMessage {
  static id = 'currency_indicator.GetLastValuesResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLastValuesResponse();
    GetLastValuesResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLastValuesResponse) {
    _instance.values = _instance.values || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLastValuesResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          const messageInitializer1 = new common_message006.CurrencyIndicatorModel();
          _reader.readMessage(
            messageInitializer1,
            common_message006.CurrencyIndicatorModel.deserializeBinaryFromReader
          );
          (_instance.values = _instance.values || []).push(messageInitializer1);
          break;
        default:
          _reader.skipField();
      }
    }

    GetLastValuesResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLastValuesResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.values && _instance.values.length) {
      _writer.writeRepeatedMessage(
        1,
        _instance.values as any,
        common_message006.CurrencyIndicatorModel.serializeBinaryToWriter
      );
    }
  }

  private _values?: common_message006.CurrencyIndicatorModel[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLastValuesResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetLastValuesResponse.AsObject>) {
    _value = _value || {};
    this.values = (_value.values || []).map(
      m => new common_message006.CurrencyIndicatorModel(m)
    );
    GetLastValuesResponse.refineValues(this);
  }
  get values(): common_message006.CurrencyIndicatorModel[] | undefined {
    return this._values;
  }
  set values(value: common_message006.CurrencyIndicatorModel[] | undefined) {
    this._values = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLastValuesResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLastValuesResponse.AsObject {
    return {
      values: (this.values || []).map(m => m.toObject())
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLastValuesResponse.AsProtobufJSON {
    return {
      values: (this.values || []).map(m => m.toProtobufJSON(options))
    };
  }
}
export module GetLastValuesResponse {
  /**
   * Standard JavaScript object representation for GetLastValuesResponse
   */
  export interface AsObject {
    values?: common_message006.CurrencyIndicatorModel.AsObject[];
  }

  /**
   * Protobuf JSON representation for GetLastValuesResponse
   */
  export interface AsProtobufJSON {
    values: common_message006.CurrencyIndicatorModel.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for currency_indicator.GetLastValuesOnlineRequest
 */
export class GetLastValuesOnlineRequest implements GrpcMessage {
  static id = 'currency_indicator.GetLastValuesOnlineRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLastValuesOnlineRequest();
    GetLastValuesOnlineRequest.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLastValuesOnlineRequest) {
    _instance.currencyId = _instance.currencyId || 0;
    _instance.currencyYahooNemonic = _instance.currencyYahooNemonic || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLastValuesOnlineRequest,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.currencyId = _reader.readInt32();
          break;
        case 2:
          _instance.currencyYahooNemonic = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    GetLastValuesOnlineRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLastValuesOnlineRequest,
    _writer: BinaryWriter
  ) {
    if (_instance.currencyId) {
      _writer.writeInt32(1, _instance.currencyId);
    }
    if (_instance.currencyYahooNemonic) {
      _writer.writeString(2, _instance.currencyYahooNemonic);
    }
  }

  private _currencyId: number;
  private _currencyYahooNemonic: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLastValuesOnlineRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetLastValuesOnlineRequest.AsObject>) {
    _value = _value || {};
    this.currencyId = _value.currencyId;
    this.currencyYahooNemonic = _value.currencyYahooNemonic;
    GetLastValuesOnlineRequest.refineValues(this);
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get currencyYahooNemonic(): string {
    return this._currencyYahooNemonic;
  }
  set currencyYahooNemonic(value: string) {
    this._currencyYahooNemonic = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLastValuesOnlineRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLastValuesOnlineRequest.AsObject {
    return {
      currencyId: this.currencyId,
      currencyYahooNemonic: this.currencyYahooNemonic
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLastValuesOnlineRequest.AsProtobufJSON {
    return {
      currencyId: this.currencyId,
      currencyYahooNemonic: this.currencyYahooNemonic
    };
  }
}
export module GetLastValuesOnlineRequest {
  /**
   * Standard JavaScript object representation for GetLastValuesOnlineRequest
   */
  export interface AsObject {
    currencyId: number;
    currencyYahooNemonic: string;
  }

  /**
   * Protobuf JSON representation for GetLastValuesOnlineRequest
   */
  export interface AsProtobufJSON {
    currencyId: number;
    currencyYahooNemonic: string;
  }
}

/**
 * Message implementation for currency_indicator.GetLastValuesOnlineResponse
 */
export class GetLastValuesOnlineResponse implements GrpcMessage {
  static id = 'currency_indicator.GetLastValuesOnlineResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new GetLastValuesOnlineResponse();
    GetLastValuesOnlineResponse.deserializeBinaryFromReader(
      instance,
      new BinaryReader(bytes)
    );
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: GetLastValuesOnlineResponse) {
    _instance.currencyId = _instance.currencyId || 0;
    _instance.currencyYahooNemonic = _instance.currencyYahooNemonic || '';
    _instance.date = _instance.date || undefined;
    _instance.ask = _instance.ask || 0;
    _instance.bid = _instance.bid || 0;
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(
    _instance: GetLastValuesOnlineResponse,
    _reader: BinaryReader
  ) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.currencyId = _reader.readInt32();
          break;
        case 2:
          _instance.currencyYahooNemonic = _reader.readString();
          break;
        case 4:
          _instance.date = new googleProtobuf001.Timestamp();
          _reader.readMessage(
            _instance.date,
            googleProtobuf001.Timestamp.deserializeBinaryFromReader
          );
          break;
        case 5:
          _instance.ask = _reader.readDouble();
          break;
        case 6:
          _instance.bid = _reader.readDouble();
          break;
        default:
          _reader.skipField();
      }
    }

    GetLastValuesOnlineResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(
    _instance: GetLastValuesOnlineResponse,
    _writer: BinaryWriter
  ) {
    if (_instance.currencyId) {
      _writer.writeInt32(1, _instance.currencyId);
    }
    if (_instance.currencyYahooNemonic) {
      _writer.writeString(2, _instance.currencyYahooNemonic);
    }
    if (_instance.date) {
      _writer.writeMessage(
        4,
        _instance.date as any,
        googleProtobuf001.Timestamp.serializeBinaryToWriter
      );
    }
    if (_instance.ask) {
      _writer.writeDouble(5, _instance.ask);
    }
    if (_instance.bid) {
      _writer.writeDouble(6, _instance.bid);
    }
  }

  private _currencyId: number;
  private _currencyYahooNemonic: string;
  private _date?: googleProtobuf001.Timestamp;
  private _ask: number;
  private _bid: number;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of GetLastValuesOnlineResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<GetLastValuesOnlineResponse.AsObject>) {
    _value = _value || {};
    this.currencyId = _value.currencyId;
    this.currencyYahooNemonic = _value.currencyYahooNemonic;
    this.date = _value.date
      ? new googleProtobuf001.Timestamp(_value.date)
      : undefined;
    this.ask = _value.ask;
    this.bid = _value.bid;
    GetLastValuesOnlineResponse.refineValues(this);
  }
  get currencyId(): number {
    return this._currencyId;
  }
  set currencyId(value: number) {
    this._currencyId = value;
  }
  get currencyYahooNemonic(): string {
    return this._currencyYahooNemonic;
  }
  set currencyYahooNemonic(value: string) {
    this._currencyYahooNemonic = value;
  }
  get date(): googleProtobuf001.Timestamp | undefined {
    return this._date;
  }
  set date(value: googleProtobuf001.Timestamp | undefined) {
    this._date = value;
  }
  get ask(): number {
    return this._ask;
  }
  set ask(value: number) {
    this._ask = value;
  }
  get bid(): number {
    return this._bid;
  }
  set bid(value: number) {
    this._bid = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    GetLastValuesOnlineResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): GetLastValuesOnlineResponse.AsObject {
    return {
      currencyId: this.currencyId,
      currencyYahooNemonic: this.currencyYahooNemonic,
      date: this.date ? this.date.toObject() : undefined,
      ask: this.ask,
      bid: this.bid
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions
  ): GetLastValuesOnlineResponse.AsProtobufJSON {
    return {
      currencyId: this.currencyId,
      currencyYahooNemonic: this.currencyYahooNemonic,
      date: this.date ? this.date.toProtobufJSON(options) : null,
      ask: this.ask,
      bid: this.bid
    };
  }
}
export module GetLastValuesOnlineResponse {
  /**
   * Standard JavaScript object representation for GetLastValuesOnlineResponse
   */
  export interface AsObject {
    currencyId: number;
    currencyYahooNemonic: string;
    date?: googleProtobuf001.Timestamp.AsObject;
    ask: number;
    bid: number;
  }

  /**
   * Protobuf JSON representation for GetLastValuesOnlineResponse
   */
  export interface AsProtobufJSON {
    currencyId: number;
    currencyYahooNemonic: string;
    date: googleProtobuf001.Timestamp.AsProtobufJSON | null;
    ask: number;
    bid: number;
  }
}
